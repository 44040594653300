import $ from "jquery";
window.$ = window.jQuery = $;
window.Sifter = require("sifter");
window.MicroPlugin = require("microplugin");

import "selectize/dist/js/selectize.js";

require("imports-loader?wrapper=window!selectize");

const selectize = () => {
  $(function () {
    console.log("selectize init");

    $(".js-input-multiple").selectize({
      plugins: ["remove_button"],
      delimiter: ",",
      persist: false,
      hideSelected: true,
      create: false,
      allowEmptyOption: false,
    });

    $("select.js-input-tags").selectize({
      plugins: ["remove_button"],
      delimiter: ",",
      persist: true,
      hideSelected: true,
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      },
    });
  });
};

export { selectize };
