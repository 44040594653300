import $ from 'jquery';
import "bootstrap5";

const bootstrap = () => {      
  $(function() {
    console.log("bootstrap init");
    $(
      'a[rel~=tooltip], [data-toggle="tooltip"], .has-tooltip, span[title]'
    ).tooltip();
  });
};
  
export { bootstrap }; 